<template>
  <div>
    <template
      v-for="(user,key) in productGroups.data"
    >
      <template>
        <b-card
          :key="key"
          title="Ürün Grupları"
          :sub-title="user[0].currency"
        >
          <b-row>
            <b-col>
              <apex-donut-chart
                :categories-data="productGroups.chart[key].categories"
                :series-data="productGroups.chart[key].series"
                :pie-labels="false"
              />
            </b-col>
            <b-col class="d-flex align-items-center">
              <b-table-simple
                bordered
                striped
                small
                class="w-100 font-small-2"
              >
                <b-thead>
                  <b-tr>
                    <b-th>Ürün Grubu</b-th>
                    <b-th class="text-center">
                      Miktar
                    </b-th>
                    <b-th class="text-right text-nowrap width-100">
                      Toplam Tutar
                    </b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr
                    v-for="item in user"
                    :key="item.id"
                  >
                    <b-td class="text-primary font-weight-bold">
                      {{ item.title }}
                    </b-td>
                    <b-td class="text-center">
                      {{ item.quantity }}
                    </b-td>
                    <b-td class="text-right text-nowrap">
                      {{ item.totals | toCurrency }} {{ item.currency }}
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </b-card>
      </template>
    </template>
    <b-card
      v-if="!productGroups.data.length"
      title="Ürün Grupları"
    >
      <empty-alert />
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTableSimple, BThead, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import ApexDonutChart from '@/views/Admin/Reports/charts/ApexDonutChart.vue'
import EmptyAlert from '@/views/Admin/Reports/common/EmptyAlert.vue'

export default {
  name: 'ProductGroups',
  components: {
    BCard,
    BRow,
    BCol,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    ApexDonutChart,
    EmptyAlert,
  },
  computed: {
    productGroups() {
      return this.$store.getters['proformaReports/productGroups']
    },
  },
}
</script>
