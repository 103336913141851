<template>
  <b-card>
    <b-row>
      <b-col>
        <b-form-group
          label="Başlangıç Tarihi"
          label-for="sdate"
        >
          <b-form-datepicker
            id="sdate"
            v-model="filterParams.sdate"
            v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
            @input="getReport"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Bitiş Tarihi"
          label-for="edate"
        >
          <b-form-datepicker
            id="edate"
            v-model="filterParams.edate"
            v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
            @input="getReport"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Marka"
          label-for="id_brands"
        >
          <v-select
            v-model="filterParams.id_brands"
            :options="brands"
            label="title"
            :reduce="item => item.id"
            placeholder="Seçiniz"
            @input="getReport"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Temsilci"
          label-for="id_users"
        >
          <v-select
            v-model="filterParams.id_users"
            :options="users"
            label="name"
            :reduce="item => item.id"
            placeholder="Seçiniz"
            @input="getReport"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BRow, BCol, BCard, BFormDatepicker, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'FilterData',
  components: {
    BRow,
    BCol,
    BCard,
    BFormDatepicker,
    BFormGroup,
    vSelect,
  },
  props: {
    getReport: {
      type: Function,
      require: true,
    },
  },
  computed: {
    filterParams() {
      return this.$store.getters['proformaReports/filterParams']
    },
    users() {
      return this.$store.getters['users/dataList']
    },
    brands() {
      return this.$store.getters['brands/dataList']
    },
  },
  created() {
    this.getBrands()
    this.getUsers()
  },
  methods: {
    getBrands() {
      this.$store.dispatch('brands/getDataList', {
        select: [
          'brands.id AS id',
          'brands.title AS title',
        ],
      })
    },
    getUsers() {
      this.$store.dispatch('users/getDataList', {
        select: ['users.id AS id', 'users.name AS name'],
        where: {
          'users.status': 1,
          'users.id_user_types >': 1,
        },
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
